.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.App span {
  color: white;
  font-size: 8rem;
  font-family: monospace;
  transform: translateY(-50%);
}